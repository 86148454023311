import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import ListIndexes from "./pages/IndexList.js";
import IndexOverview from "./pages/Index";
import AddSecurity from "./pages/AddSecurity.js";
import AddIndex from "./pages/IndexCreate.js";
import IndexEdit from "./pages/IndexEdit.js";
import IndexSecuritiesEdit from "./pages/IndexSecuritiesEdit.js";
import Timeline from "./pages/IndexChangelog.js";
import SecurityList from "./pages/SecuritiesList.js";
import IndexList from "./pages/IndexList.js";
import {getAllIndexes} from "./api/admin";
import {useLocation} from "react-router-dom";

function RoutingApp() {
    const [indexes, setIndexes] = useState([]);
    const location = useLocation();
    useEffect(() => {
        const fetchData = async () => {
            if (location.pathname === '/' || indexes.length <= 0) {
                const response = await getAllIndexes();
                setIndexes(response);
            }
        };
        fetchData()
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<App indexes={indexes}/>}>
                <Route index element={<ListIndexes indexes={indexes}/>}/> {/* Left Side Pane*/}
                <Route index element={<IndexList indexes={indexes}/>}/> {/* Table Pane*/}
                <Route path="/securities" element={<SecurityList/>}/>
                <Route path="/securities/:security/edit"
                       element={<AddSecurity/>}/> {/* TODO: Change this to edit security */}
                <Route path="/index/new" element={<AddIndex/>}/>
                <Route path="/index/:code" element={<IndexOverview/>}/>
                <Route path="/index/:code/edit" element={<IndexEdit/>}/>
                <Route path="/index/:code/changelog" element={<Timeline/>}/>
                <Route path="/index/:code/securities" element={<IndexSecuritiesEdit/>}/>
            </Route>
        </Routes>
    )
}

ReactDOM.render(
    <BrowserRouter>
        <RoutingApp/>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
