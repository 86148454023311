import "../App.css";

const AddSecurity = () => {
    return (
        <section className="AddSecurity">
            <h2>Add New Security</h2>

            <form>
                <div className="AddSecurityFormContainer">
                    <input type="text" placeholder="Company Name"/>
                    <input type="text" placeholder="URL"/>
                    <input type="text" placeholder="Location"/>
                    <input type="text" placeholder="Year Founded"/>
                    <input type="text" placeholder="# of Employees"/>
                    <input type="textarea" placeholder="Summary"/>
                    <input type="text" placeholder="Link for DJ's Connecting Magic"/>
                </div>
                <div className="AddSecurityFormContainer">
                    <h4>Set as...</h4>
                    <div className="RadioContainer">
                        <input type="radio" value="Active" name="SecurityInitialState"/>{" "}
                        Active
                        <input
                            type="radio"
                            value="Inactive"
                            name="SecurityInitialState"
                        />{" "}
                        Inactive
                    </div>
                    <select>
                        <option value="MD100">Add to Index...</option>
                        <option value="MD100">Medtech100</option>
                        <option value="RD100">R&D100</option>
                        <option value="Inactive">Leave Unassigned</option>
                    </select>
                    <button>+ Create Security</button>
                </div>
            </form>
        </section>
    );
}

export default AddSecurity;
