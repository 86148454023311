import "../App.css";
import {useState} from "react";
import {createIndex, searchSecurities, validateIndex} from "../api/admin";
import TypeAheadDropDown from "../components/TypeAheadDropDown";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";


/**
 * Future iteration should allow you to choose when a stock was added or removed from the index
 *
 */

const Stages = {
    FirstStage: "FirstStage",
    SecondStage: "SecondStage"
}

const IndexCreate = () => {
    const [formData, setFormData] = useState({
        indexName: "",
        indexUrl: "",
        indexBrand: "",
        indexCode: "",
        indexDescription: "",
        startOfIndexDate: null,
        securities: [] // Security Object { securityId: number, action: ADDED, actionDate: datetime in ISO8601 format 2022-02-20T16:00:00-04:00
    });
    const [securitySuggestions, setSecuritySuggestions] = useState([]);
    const [securitySuggestionQuery, setSecuritySuggestionQuery] = useState("");
    const [stage, setStage] = useState(Stages.FirstStage);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    let navigate = useNavigate();

    const submitStageTwo = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const securityActions = formData.securities.map(sec => {
                return {
                    securityId: sec.securityId,
                    action: "ADDED",
                    actionDate: sec.oldestPriceTime.toISO()
                }
            })

            const {indexId} = await createIndex(formData.indexName, formData.indexDescription, formData.indexUrl, formData.indexBrand, formData.indexCode, securityActions)
            navigate("/");
        } catch (e) {
            setErrorMessage(e);
            setStage(Stages.FirstStage)
            setLoading(false);
        }
    }

    const submitFirstStage = async (e) => {
        try {
            setLoading(true);
            const {valid, reason} = await validateIndex({
                name: formData.indexName,
                description: formData.indexDescription,
                website: formData.indexUrl,
                brand: formData.indexBrand,
                code: formData.indexCode
            });

            if (!valid) {
                setErrorMessage(reason);
                return
            }

            setStage(Stages.SecondStage)
            setErrorMessage("");
        } catch (e) {
            setErrorMessage("internal api error...");
            console.log(e)
        } finally {
            setLoading(false);
        }
    }

    const removeSecurityFromIndex = (symbol) => {
        setFormData({
            ...formData,
            securities: formData.securities.filter(v => v.symbol !== symbol)
        })
    }

    const updateQuery = async (e) => {
        const value = e.target.value;
        setSecuritySuggestionQuery(value)

        if (value.trim() === '') {
            setSecuritySuggestions([]);
            return
        }

        const {securities} = await searchSecurities(value, 10);

        const translationTable = {
            "XNYS": "NYSE",
            "XNAS": "NASDAQ",
            "XPHL": "PHLX",
            "BATS": "CBOE"
        }

        let newSuggestions = securities.map(sec => {
            let exchangeShortName = sec.exchangeName;
            if (exchangeShortName in translationTable) exchangeShortName = translationTable[exchangeShortName];
            return {
                securityId: sec.securityId,
                symbol: sec.symbol,
                name: sec.name,
                region: sec.region,
                logoUrl: sec.logoUrl,
                industry: sec.industry,
                websiteUrl: sec.websiteUrl,
                description: sec.description,
                oldestPriceTime: DateTime.fromISO(sec.oldestPriceTime),
                oldestPrice: sec.oldestPrice,
                currencyName: sec.currencyName,
                currencySymbol: sec.currencySymbol,
                exchangeName: exchangeShortName,
                exchangeMic: sec.exchangeMic,
                exchangeLongName: sec.exchangeLongName,
                exchangeRegion: sec.exchangeRegion,
                issueType: sec.issueType,
                sector: sec.sector,
                primarySicCode: sec.primarySicCode,
                tags: sec.tags,
            }
        });

        setSecuritySuggestions(newSuggestions)
    }

    const suggestionClicked = (symbol) => {
        securitySuggestions.forEach(sec => {
            if (sec.symbol === symbol) {
                setFormData({
                    ...formData,
                    securities: [...formData.securities, sec]
                })
            }
        });
    }

    if (loading) return <div/>

    return (
        <section className="AddSecurity">
            <h2>Create New Index</h2>
            {
                stage === Stages.FirstStage && (
                    <>
                        {/*TODO @Elise style the error message when it occurs */}
                        {
                            errorMessage !== "" && (
                                <div>
                                    {errorMessage}
                                </div>
                            )
                        }
                        <StepOne
                            formData={formData}
                            setFormData={setFormData}
                        />
                        <div className="AddSecurityFormContainer">
                            <button onClick={submitFirstStage}>Next >></button>
                        </div>
                    </>
                )
            }
            {
                stage === Stages.SecondStage && (
                    <>
                        <h2> Add Securities </h2>
                        <StepTwo
                            securitySuggestionQuery={securitySuggestionQuery}
                            updateQuery={updateQuery}
                            securitySuggestions={securitySuggestions}
                            formData={formData}
                            removeSecurityFromIndex={removeSecurityFromIndex}
                            suggestionClicked={suggestionClicked}
                        />
                        <div className="AddSecurityFormContainer">
                            <button onClick={() => {
                                setStage(Stages.FirstStage)
                            }}>Back
                            </button>
                        </div>
                        <div className="AddSecurityFormContainer">
                            <button onClick={submitStageTwo}>Create Index +</button>
                        </div>
                    </>
                )
            }
        </section>
    );
}

const StepOne = ({formData, setFormData}) => (
    <form>
        <div className="AddSecurityFormContainer">
            <input type="text" placeholder="Index Name" value={formData.indexName}
                   onChange={(e) => setFormData({...formData, indexName: e.target.value})}/>
            <input type="text" placeholder="Index Code" value={formData.indexCode}
                   onChange={(e) => setFormData({...formData, indexCode: e.target.value})}/>
            <input type="text" placeholder="Index Homepage URL" value={formData.indexUrl}
                   onChange={(e) => setFormData({...formData, indexUrl: e.target.value})}/>
            <input type="text" placeholder="Index Brand" value={formData.indexBrand}
                   onChange={(e) => setFormData({...formData, indexBrand: e.target.value})}/>
            <input type="textarea" placeholder="Index Description" value={formData.indexDescription}
                   onChange={(e) => setFormData({...formData, indexDescription: e.target.value})}/>
        </div>
    </form>
)

const StepTwo = ({
                     securitySuggestionQuery,
                     updateQuery,
                     securitySuggestions,
                     suggestionClicked,
                     formData,
                     removeSecurityFromIndex,
                 }) => (
    <section className="AddSecurity">
        <div className="IndexSearchAdd">
            <TypeAheadDropDown
                query={securitySuggestionQuery}
                setQuery={updateQuery}
                suggestions={securitySuggestions}
                suggestionClicked={suggestionClicked}
            />
        </div>
        <div className="IndexList">
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Logo</th>
                    <th>Symbol</th>
                    <th>Exchange</th>
                    <th>Region</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {formData && formData.securities.map((security, idx) =>
                    <tr key={security.symbol}>
                        <td>{security.name}</td>
                        <td><img src={security.logoUrl} alt={`Image of ${security.name}`}/></td>
                        <td>{security.symbol}</td>
                        <td>{security.exchangeShortName}</td>
                        <td>{security.region}</td>
                        <td onClick={() => removeSecurityFromIndex(security.symbol)}>Remove</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </section>
)

export default IndexCreate;
