import "./App.css";
import Header from "./components/Header.js";
import {Outlet,Link} from "react-router-dom";

function App({indexes}) {
    return (
        <div className="App">
            <Header/>
            <div className="IndexWrap">
                <section className="IndexNav">
                    <ul>
                        {
                            indexes && indexes.map(index => {
                                return (
                                    <li key={index.code}>
                                        <Link to={`/index/${index.code}`}>{index.name}</Link>
                                    </li>
                                )
                            })
                        }
                        <li>
                            <Link to="/index/new">+ Create New Index</Link>
                        </li>
                    </ul>
                </section>
                <Outlet/>
            </div>
        </div>
    );
}

export default App;
