import "../App.css";
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getIndexByCode} from "../api/admin";
import uPlot from 'uplot';
import UplotReact from 'uplot-react';
import 'uplot/dist/uPlot.min.css';
import {DateTime} from 'luxon';
import Chart from '../components/Chart.js';

const Index = () => {
    const {code} = useParams();
    const [data, setData] = useState({
        name: "",
        code: "",
        status: "",
        currentPrice: "",
        intradayPrices: [],
        fivedayPrices: [],
        historicalPrices: [],
        actions: [],
    })
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            const indexData = await getIndexByCode(code);
            setData({
                ...data,
                name: indexData.name,
                code: indexData.code,
                status: indexData.status,
                currentPrice: indexData.price,
                intradayPrices: indexData.intradayPrices,
                fiveDayPrices: indexData.fiveDayPrices,
                historicalPrices: indexData.historicalPrices,
                securities: indexData.securities
            });

            setIsLoading(false);
        }
        getData();
    }, [code])

    if (isLoading) return <div>Loading...</div>;

    return (
        <section className="MainWrapper">
            <h1>{data.name} Overview</h1>
            <Chart
                intradayPrices={data.intradayPrices}
                fiveDayPrices={data.fiveDayPrices}
                historicalPrices={data.historicalPrices}
            />
            <div className="ButtonContainer">
                <div className="IndexSearchAdd">
                    <div className="IndexButtonContainer">
                        <button>
                            <Link to={`/index/${code}/edit`}>Edit Index</Link>
                        </button>
                    </div>
                </div>

                <div className="IndexSearchAdd">
                    <div className="IndexButtonContainer">
                        <button>
                            <Link to={`/index/${code}/changelog`}>View Changelog</Link>
                        </button>
                    </div>
                </div>
            </div>
            <div className="SecuritiesList">
                <table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Symbol</th>
                        <th>Logo</th>
                        <th>Current Price</th>
                        <th>Previous Close Price</th>
                        <th>Sparkline</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.securities && data.securities.map(security => {
                            let xData = [];
                            let yData = [];
                            security.intradayPrices && security.intradayPrices.forEach(ip => {
                                const {price, time} = ip;
                                const t = DateTime.fromISO(time);
                                const p = parseFloat(price).toFixed(2);
                                xData.push(t.toUnixInteger());
                                yData.push(p);
                            });
                            return (
                                <tr>
                                    <td>{security.name}</td>
                                    <td>{security.symbol}</td>
                                    <td><img src={security.logoUrl} alt={`image of ${security.name} logo`}/></td>
                                    <td>${parseFloat(security.latestPrice).toFixed(2)}</td>
                                    <td>${parseFloat(security.previousClosePrice).toFixed(2)}</td>
                                    <td>
                                        <UplotReact
                                            options={{
                                                width: 150,
                                                height: 30,
                                                class: "spark",
                                                pxAlign: false,
                                                cursor: {
                                                    show: false
                                                },
                                                select: {
                                                    show: false,
                                                },
                                                legend: {
                                                    show: false,
                                                },
                                                scales: {
                                                    x: {
                                                        time: false,
                                                    },
                                                },
                                                axes: [
                                                    {
                                                        show: false,
                                                    },
                                                    {
                                                        show: false,
                                                    }
                                                ],
                                                series: [
                                                    {},
                                                    {
                                                        stroke: "#ec5b2e",
                                                        fill: "#ffb39c",
                                                    },
                                                ],
                                            }}
                                            data={[xData, yData]}
                                            // target={target}
                                            onCreate={(chart) => {
                                            }}
                                            onDelete={(chart) => {
                                            }}
                                        /></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default Index;
