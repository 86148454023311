import "../App.css";
import 'uplot/dist/uPlot.min.css';
import React, {useEffect, useState} from "react";
import {getSecurities, searchSecurities} from "../api/admin";
import Pagination from "../components/Pagination.js"


const SecurityList = ({}) => {
    const [securities, setSecurities] = useState([]);
    const [pagination, setPagination] = useState({
        totalPages: null,
        currentPage: null,
    });
    const [status, setStatus] = useState('');

    const [query, setQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const response = await getSecurities();
            setSecurities(response.securities);

            const totalPages = Math.ceil(response.securities.length / 12)
            setPagination({
                ...pagination,
                totalPages,
                currentPage: 1
            })
        };

        fetchData()
            .catch(console.error)
    }, [])

    const prevPage = () => {
        const newPage = pagination.currentPage - 1
        if (newPage < 1) return
        setPagination({
            ...pagination,
            currentPage: newPage
        });
    }

    const nextPage = () => {
        const newPage = pagination.currentPage + 1
        if (newPage > pagination.totalPages) return
        setPagination({
            ...pagination,
            currentPage: newPage
        });
    }

    const gotoPage = (pageNumber) => {
        if (pageNumber > pagination.totalPages || pageNumber <= 0 || pageNumber === pagination.currentPage) return
        setPagination({
            ...pagination,
            currentPage: pageNumber
        })
    }

    const search = async (e) => {
        e.preventDefault();
        setStatus("fetching");
        const {securities, totalSecurities} = await searchSecurities(query, 10000);
        setSecurities(securities);
        setPagination({
            ...pagination,
            totalPages: Math.ceil(totalSecurities / 12),
            currentPage: 1
        });
        setStatus("");
    }

    if (securities.length <= 0 || status === 'fetching') {
        return (
            <div>
                loading...
            </div>
        )
    }

    return (
        <section className="MainWrapper">
            <h1>All Securities</h1>
            <div className="all-securities-search">
                <div>Query: {query}</div>
                <form onSubmit={search}>
                    <input type="text" placeholder="Search..." onChange={(e) => setQuery(e.target.value)}/>
                    <button type="submit" onClick={search}>Submit</button>
                </form>
            </div>
            <div className="IndexList">
                <table>
                    <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Name</th>
                        <th>Logo</th>
                        <th>Region</th>
                        <th>Industry</th>
                        <th>Website</th>
                        <th>Description</th>
                        <th>Oldest Price</th>
                        <th>Currency Name</th>
                        <th>Currency Symbol</th>
                        <th>Exchange Name</th>
                        <th>Exchange MIC</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        securities && securities.slice((pagination.currentPage - 1) * 12, (pagination.currentPage * 12) - 1).map((security) => {
                            return (
                                <tr key={security.symbol}>
                                    <td>{security.symbol}</td>
                                    <td><span className="ellipsis">{security.name}</span></td>
                                    <td><img src={security.logoUrl} alt={`Image for ${security.name}`}/></td>
                                    <td>{security.region}</td>
                                    <td><span className="ellipsis">{security.industry}</span></td>
                                    <td><a href={security.website} target={"_blank"}>{security.website}</a></td>
                                    <td><span className="ellipsis">{security.description}</span></td>
                                    <td>{security.oldestPriceTime}</td>
                                    <td>{security.currencyName}</td>
                                    <td>{security.currencySymbol}</td>
                                    <td>{security.exchangeName}</td>
                                    <td>{security.exchangeMIC}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
            <Pagination
                currentPage={pagination.currentPage}
                totalPages={pagination.totalPages}
                gotoPreviousPage={prevPage}
                gotoNextPage={nextPage}
                gotoPage={gotoPage}
            />

        </section>
    );
}


export default SecurityList;
