import './TypeAheadDropDown.css'
import {useState} from "react";


const TypeaheadDropdown = ({query, setQuery, suggestions, suggestionClicked}) => {
    const [isOpen, setToggleOpen] = useState(false);

    return (
        <div
        >
            <input onChange={setQuery}
                   placeholder="Search a security..."
                   value={query}
                   type="text"
                   className="TypeAheadDropDown-input"
                   onFocus={() => setToggleOpen(true)}
                   onBlur={(e) => {
                       // firefox onBlur issue workaround
                       if (e.nativeEvent.explicitOriginalTarget &&
                           e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
                           return;
                       }

                       if (isOpen) {
                           setTimeout(() => {
                               setToggleOpen(false);
                           }, 200);
                       }
                   }}
            />

            <div className="TypeAheadDropDown" hidden={!isOpen}>
                {suggestions && suggestions.map(suggestion => {
                    return (
                        <ul key={suggestion.symbol}>
                            <li
                                onClick={() => suggestionClicked(suggestion.symbol)}
                            >
                                <div>
                                    <img src={suggestion.logoUrl} alt={`${suggestion.name} Logo`}/>
                                </div>
                                <div>
                                    <div className="suggestion-name">{suggestion.name}</div>
                                    <div>{suggestion.symbol}</div>
                                    <div>{suggestion.exchangeName}({suggestion.exchangeLongName})</div>
                                </div>
                            </li>
                        </ul>
                    )
                })}
            </div>
        </div>
    )
}

export default TypeaheadDropdown;