import "../App.css";
import "../components/TypeAheadDropDown.css";
import TypeAheadDropDown from "../components/TypeAheadDropDown.js";
import {useParams} from "react-router-dom";


const IndexSecuritiesEdit = () => {
    const {code} = useParams();
    return (
        <section className="AddSecurity">
            {/* <h2>INDEXNAME</h2>*/}
            {/* <span><Link to="/EditIndex">Index Info</Link> - Edit Securities</span> */}
            <div className="IndexSearchAdd">
                <TypeAheadDropDown />
                <div className="IndexButtonContainer">
                    <button>+ Add to Index</button>
                </div>
            </div>
            <div className="IndexList">
                {/*  <h2>Associated Securities</h2> */}
                <table>
                    <thead>
                    <tr>
                        <th>Status</th>
                        <th>Letters</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Graph</th>
                        <th>Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Switch</td>
                        <td>ES1</td>
                        <td>Example Security 1</td>
                        <td>$1000</td>
                        <td>~~~~~~~~</td>
                        <td>EDIT</td>
                    </tr>
                    <tr>
                        <td>Switch</td>
                        <td>ES2</td>
                        <td>Example Security 2</td>
                        <td>Unavailable</td>
                        <td>~~~~~~~~</td>
                        <td>EDIT</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default IndexSecuritiesEdit;
