import "../App.css";
import {Link} from "react-router-dom";

function Header() {
    return (
        <header className="IndexHeader">
            <Link to="/"><h2>Pliny Financial Indices</h2></Link>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/securities">All Securities</Link>
                </li>
            </ul>
            {/*<button>Logout</button>*/}
        </header>
    );
}

export default Header;
