import "../App.css";
import 'uplot/dist/uPlot.min.css';
import {DateTime} from 'luxon';
import UplotReact from "uplot-react";
import {useState} from "react";

const getSize = () => {
    try {
        const chartContainerEl = document.getElementById('market-summary-content--graph-id');
        const rec = chartContainerEl.getBoundingClientRect();
        return {
            width: rec.width, height: rec.width / 1.7,
        };
    } catch (e) {
        return {width: 150, height: 100}
    }
}

const opts = {
    height: 300,
    width: 600,
    series: [{}, {
        stroke: '#ec5b2e', width: 2, label: "Price", value: (u, v) => parseFloat(v).toFixed(2),
    }], axes: [{}, {
        size: 70, values: (u, vals) => vals.map(v => "$" + parseFloat(v).toFixed(2))
    }], legend: {
        show: false,
    }, scales: {
        x: {
            range: (u, min, max) => {
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const now = DateTime.now();
                const m = min * 1000;
                const mm = max * 1000;
                const minT = DateTime.fromMillis(m);
                const maxT = DateTime.fromMillis(mm);
                const minTF = minT.setZone("America/New_York").set({
                    day: minT.day - 1,
                    second: 0,
                    minute: 0,
                    hour: 0
                }).setZone(timezone.toString());
                const maxTF = maxT.setZone("America/New_York").set({
                    second: 0,
                    minute: 0,
                    hour: 0,
                    day: now.day + 1,
                    month: now.month,
                    year: now.year
                }).setZone(timezone);
                return [minTF.toMillis() / 1000, maxTF.toMillis() / 1000];
            },
        }
    }
};

const Chart = ({intradayPrices, fiveDayPrices, historicalPrices}) => {
    const [chartType, setChartType] = useState('one-day')

    const onChartType = (newChartType) => {
        setChartType(newChartType);
    }

    let oneDay = {x: [], y: []};
    let fiveDays = {x: [], y: []};
    let oneMonth = {x: [], y: []};
    let sixMonths = {x: [], y: []};
    let yearToDate = {x: [], y: []};
    let oneYear = {x: [], y: []};
    let fiveYear = {x: [], y: []};
    let maxChart = {x: [], y: []};

    intradayPrices && intradayPrices.length > 0 && intradayPrices.forEach(ip => {
        const {price, time} = ip;
        const t = DateTime.fromISO(time);
        const p = parseFloat(price).toFixed(2);
        oneDay.x.push(t.toUnixInteger());
        oneDay.y.push(p);
    });

    fiveDayPrices && fiveDayPrices.forEach(fdp => {
        const {price, time} = fdp;
        const t = DateTime.fromISO(time);
        const p = parseFloat(price).toFixed(2);
        fiveDays.x.push(t.toUnixInteger());
        fiveDays.y.push(p);
    });

    const now = DateTime.now();
    const ytd = DateTime.fromObject({year: now.year, month: 1, day: 1});

    historicalPrices.forEach(hp => {
        const {price, time} = hp;
        const t = DateTime.fromISO(time);
        const p = parseFloat(price).toFixed(2);
        maxChart.x.push(t.toUnixInteger())
        maxChart.y.push(p);

        if (t.toUnixInteger() <= DateTime.now().toUnixInteger() && t.toUnixInteger() >= DateTime.now().minus({months: 1}).toUnixInteger()) {
            oneMonth.x.push(t.toUnixInteger())
            oneMonth.y.push(p);
        }

        if (t <= DateTime.now() && t >= DateTime.now().minus({months: 6})) {
            sixMonths.x.push(t.toUnixInteger())
            sixMonths.y.push(p);
        }

        if (t <= DateTime.now() && t >= ytd) {
            yearToDate.x.push(t.toUnixInteger())
            yearToDate.y.push(p);
        }

        if (t <= DateTime.now() && t >= DateTime.now().minus({years: 1})) {
            oneYear.x.push(t.toUnixInteger())
            oneYear.y.push(p);
        }

        if (t <= DateTime.now() && t >= DateTime.now().minus({years: 5})) {
            fiveYear.x.push(t.toUnixInteger())
            fiveYear.y.push(p);
        }
    })
    const charts = {
        oneDay: {
            chart: 'one-day',
            data: oneDay,
            opts: {
                scales: {
                    x: {
                        range: (u, min, max) => {
                            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            const t = DateTime.fromMillis(min * 1000);
                            const minTF = t.setZone("America/New_York").set({
                                second: 0,
                                minute: 29,
                                hour: 9
                            }).setZone(timezone);
                            const maxTF = t.setZone("America/New_York").set({
                                second: 0,
                                minute: 0,
                                hour: 16
                            }).setZone(timezone);
                            return [minTF.toMillis() / 1000, maxTF.toMillis() / 1000];
                        },
                    }
                },
            }
        },
        fiveDay: {
            chart: 'five-day',
            data: fiveDays,
            opts: {
                scales: {
                    x: {
                        range: (u, min, max) => {
                            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                            const minT = DateTime.fromMillis(min * 1000);
                            const maxT = DateTime.fromMillis(max * 1000);
                            const minTF = minT.setZone("America/New_York").set({
                                day: minT.day,
                                minute: 29,
                                hour: 9
                            }).setZone(timezone);

                            const maxTF = maxT.setZone("America/New_York").set({
                                day: maxT.day,
                                second: 0,
                                minute: 0,
                                hour: 16
                            }).setZone(timezone);
                            return [minTF.toMillis() / 1000, maxTF.toMillis() / 1000];
                        },
                    }
                },
            }
        },
        oneMonth: {
            chart: 'one-month',
            data: oneMonth,
            opts: {},
        },
        sixMonth: {
            chart: 'six-month',
            data: sixMonths,
            opts: {}
        },
        yearToDate: {
            chart: 'year-to-date',
            data: yearToDate,
            opts: {}
        },
        oneYear: {
            chart: 'one-year',
            data: oneYear,
            opts: {}
        },
        fiveYear: {
            chart: 'five-year',
            data: fiveYear,
            opts: {}
        },
        max: {
            chart: 'max',
            data: maxChart,
            opts: {}
        }
    }
    return (
        <div className="IndexOverviewGraphContainer">
            <div className="market-summary--navigation">
                <div className={`market-summary--nav-item ${chartType === 'one-day' ? "active" : ""}`} id="one-day"
                     onClick={() => onChartType('one-day')}>
                    <h5>
                        <span className="market-nav-desktop">1 Day</span>
                        <span className="market-nav-mobile">1D</span>
                    </h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'five-day' ? "active" : ""}`} id="five-day"
                     onClick={() => onChartType('five-day')}>
                    <h5>
                        <span className="market-nav-desktop">5 Days</span>
                        <span className="market-nav-mobile">5D</span>
                    </h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'one-month' ? "active" : ""}`} id="one-month"
                     onClick={() => onChartType('one-month')}>
                    <h5>
                        <span className="market-nav-desktop">1 Month</span>
                        <span className="market-nav-mobile">1M</span></h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'six-month' ? "active" : ""}`} id="six-months"
                     onClick={() => onChartType('six-month')}>
                    <h5>
                        <span className="market-nav-desktop">6 Months</span>
                        <span className="market-nav-mobile">6M</span>
                    </h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'year-to-date' ? "active" : ""}`}
                     id="year-to-date"
                     onClick={() => onChartType('year-to-date')}>
                    <h5>
                        <span className="market-nav-desktop">YTD</span>
                        <span className="market-nav-mobile">YTD</span></h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'one-year' ? "active" : ""}`} id="one-year"
                     onClick={() => onChartType('one-year')}>
                    <h5>
                        <span className="market-nav-desktop">1 Year</span>
                        <span className="market-nav-mobile">1Y</span></h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'five-year' ? "active" : ""}`} id="five-years"
                     onClick={() => onChartType('five-year')}>
                    <h5>
                        <span className="market-nav-desktop">5 Years</span>
                        <span className="market-nav-mobile">5Y</span>
                    </h5>
                    <hr/>
                </div>
                <div className={`market-summary--nav-item ${chartType === 'max' ? "active" : ""}`} id="max"
                     onClick={() => onChartType('max')}>
                    <h5>
                        <span className="market-nav-desktop">Max</span>
                        <span className="market-nav-mobile">Max</span>
                    </h5>
                    <hr/>
                </div>
            </div>
            <div className="market-summary-content--graph">
                <div className="market-summary-graph" id="OneDayChart">
                    {
                        chartType === 'one-day' ? <UplotReact
                            options={{...opts, ...charts.oneDay.opts}}
                            data={[charts.oneDay.data.x, charts.oneDay.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="FiveDayChart">
                    {
                        chartType === 'five-day' ? <UplotReact
                            options={{...opts, ...charts.fiveDay.opts}}
                            data={[charts.fiveDay.data.x, charts.fiveDay.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="OneMonthChart">
                    {
                        chartType === 'one-month' ? <UplotReact
                            options={{...opts, ...charts.oneMonth.opts}}
                            data={[charts.oneMonth.data.x, charts.oneMonth.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="SixMonthChart">
                    {
                        chartType === 'six-month' ? <UplotReact
                            options={{...opts, ...charts.oneDay.opts}}
                            data={[charts.sixMonth.data.x, charts.sixMonth.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="YearToDateChart">
                    {
                        chartType === 'year-to-date' ? <UplotReact
                            options={{...opts, ...charts.yearToDate.opts}}
                            data={[charts.yearToDate.data.x, charts.yearToDate.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="OneYearChart">
                    {
                        chartType === 'one-year' ? <UplotReact
                            options={{...opts, ...charts.oneYear.opts}}
                            data={[charts.oneYear.data.x, charts.oneYear.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="FiveYearsChart">
                    {
                        chartType === 'five-year' ? <UplotReact
                            options={{...opts, ...charts.fiveYear.opts}}
                            data={[charts.fiveYear.data.x, charts.fiveYear.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
                <div className="market-summary-graph" id="MaxChart">
                    {
                        chartType === 'max' ? <UplotReact
                            options={{...opts, ...charts.max.opts}}
                            data={[charts.max.data.x, charts.max.data.y]}
                            // target={target}
                            onCreate={(chart) => {
                            }}
                            onDelete={(chart) => {
                            }}
                        /> : null
                    }
                </div>
            </div>
        </div>
    )
        ;
}

export default Chart;
