import "../App.css";
import {Link} from "react-router-dom";

function Pagination({currentPage, totalPages, gotoPreviousPage, gotoNextPage, gotoPage}) {
    let pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) pageNumbers.push(i);
    if (currentPage === null) return <div className="pagination"/>
    return (
        <div className="pagination-container">
        <div className="pagination">
            <span onClick={gotoPreviousPage}>&laquo;</span>
            {
                pageNumbers.filter(pageNumber => {
                    if (pageNumber < currentPage - 3) return false;
                    return pageNumber <= currentPage + 3;

                }).map(pageNumber =>
                    <span
                        key={pageNumber}
                        className={currentPage === pageNumber ? "active" : ""}
                        onClick={() => gotoPage(pageNumber)}>{pageNumber}
                    </span>
                )
            }
            <span onClick={gotoNextPage}>&raquo;</span>
        </div>
        </div>
    );
}

export default Pagination;
