import "../App.css";
import {useParams, Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getIndexByCode, getIndexHistory} from "../api/admin";
import {DateTime} from 'luxon';


const IndexChangelog = () => {
    const {code} = useParams();
    const [indexHistory, setHistory] = useState([]);
    const [index, setIndex] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const indexResponsePromise = getIndexByCode(code);
            const historyResponsePromise = getIndexHistory(code);
            const indexResponse = await indexResponsePromise;
            const historyResponse = await historyResponsePromise;
            setHistory(historyResponse);
            setIndex(indexResponse);
        }

        fetchData()
            .catch(console.error)
    }, [code]);

    if (indexHistory.length <= 0)
        return <div>Loading...</div>

    return (
        <section className="MainWrapper">
            <h1>{index.name} Changelog</h1>

            <div className="IndexList">

            <div className="IndexButtonContainer" id="ViewIndex">
              <button>
              <Link to={`/index/${code}`}>View Index</Link>
              </button>
            </div>
                <table>
                    <thead>
                    <tr>
                        <th>Security Name</th>
                        <th>Security Symbol</th>
                        <th>Change Made</th>
                        <th>Change Time</th>
                        <th>Latest Price</th>
                        <th>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        indexHistory && indexHistory.map((h) =>
                            <tr>
                                <td>{h.securityName}</td>
                                <td>{h.symbol}</td>
                                <td>{h.action}</td>
                                <td>{DateTime.fromISO(h.actionTime).toLocaleString(DateTime.DATE_FULL)}</td>
                                <td>{parseFloat(h.latestPrice).toFixed(2)}</td>
                                <td></td>
                            </tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default IndexChangelog;
