import "../App.css";
import uPlot from 'uplot';
import {Link} from "react-router-dom";
import UplotReact from 'uplot-react';
import {DateTime} from 'luxon';
import 'uplot/dist/uPlot.min.css';

const createStatus = (status) => {
    switch (status) {
        case  "INACTIVE":
            return (
                <td>
                    <span className="InactiveIcon"/>
                    <span className="InactiveSecurity">INACTIVE</span>
                </td>)
        case  "ACTIVE":
            return (
                <td>
                    <span className="ActiveIcon"/>
                    <span className="ActiveSecurity">ACTIVE</span>
                </td>)
        case  "CURATING":
            return (
                <td>
                    <span className="ActiveIcon"/>
                    <span className="ActiveSecurity">CURATING</span>
                </td>)
        case  "FAILED":
            return (
                <td>
                    <span className="ActiveIcon"/>
                    <span className="ActiveSecurity">FAILED</span>
                </td>)
        default:
            return (
                <td><span>Unknown State</span></td>
            )
    }
}

const IndexesList = ({indexes}) => {
    return (
        <section className="MainWrapper">
            <h1>Index Overview</h1>
            <div className="IndexList">
                <table id="IndexListTable">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Status</th>
                        <th>Price</th>
                        <th>Graph</th>
                        <th>Link</th>
                        <th>Date Added</th>
                        <th>Last Updated</th>
                        <th>Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        indexes && indexes.map((index) => {
                            let xData = [];
                            let yData = [];
                            index.pricing && index.pricing.forEach(ip => {
                                const {price, time} = ip;
                                const t = DateTime.fromISO(time);
                                const p = parseFloat(price).toFixed(2);
                                xData.push(t.toUnixInteger());
                                yData.push(p);
                            })
                            return (
                                <tr key={index.name}>
                                    <td><Link to={`/index/${index.code}`}>
                                        {index.name}
                                    </Link></td>
                                    <td>{index.code}</td>
                                    {createStatus(index.status)}
                                    <td>${parseFloat(index.price).toFixed(2)}</td>
                                    <td>
                                        <UplotReact
                                            options={{
                                                width: 150,
                                                height: 30,
                                                class: "spark",
                                                pxAlign: false,
                                                cursor: {
                                                    show: false
                                                },
                                                select: {
                                                    show: false,
                                                },
                                                legend: {
                                                    show: false,
                                                },
                                                scales: {
                                                    x: {
                                                        time: false,
                                                    },
                                                },
                                                axes: [
                                                    {
                                                        show: false,
                                                    },
                                                    {
                                                        show: false,
                                                    }
                                                ],
                                                series: [
                                                    {},
                                                    {
                                                        stroke: "#ec5b2e",
                                                        fill: "#ffb39c",
                                                    },
                                                ],
                                            }}
                                            data={[xData, yData]}
                                            // target={target}
                                            onCreate={(chart) => {
                                            }}
                                            onDelete={(chart) => {
                                            }}
                                        /></td>
                                    <td><a href={index.website} target={"_blank"}>{index.website}</a></td>
                                    <td>{DateTime.fromISO(index.createdAt).toLocaleString(DateTime.DATE_FULL)}</td>
                                    <td>{DateTime.fromISO(index.lastUpdated).toLocaleString(DateTime.DATE_FULL)}</td>
                                    <td>EDIT</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </section>
    );
}

export default IndexesList;
