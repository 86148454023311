import {DateTime} from 'luxon';

const HOST = process.env.REACT_APP_ADMIN_API ?? "http://localhost:8080";

export const getAllIndexes = async () => {
    try {
        const requestURL = `${HOST}/index`;
        const response = await fetch(requestURL);
        return await response.json();
    } catch (err) {
        throw err;
    }
};

export const createIndex = async (
    name,
    description,
    website,
    brand,
    code,
    securityActions
) => {
    /**
     * SecurityAction: {securityId: int, action: string, actionDate: string 2006-02-01T16:00:00-04:00}
     */
    try {
        const requestURL = `${HOST}/index`;
        const response = await fetch(requestURL, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                description,
                website,
                brand,
                code,
                securityActions,
            }),
        });
        return await response.json();
    } catch (err) {
        throw err;
    }
};

export const getIndexByCode = async (code) => {
    try {
        const requestURL = `${HOST}/index/${code}`;
        const response = await fetch(requestURL);
        return await response.json();
    } catch (err) {
        throw err;
    }
};

export const getIndexHistory = async (code) => {
    try {
        const requestURL = `${HOST}/index/${code}/history`;
        const response = await fetch(requestURL);
        return await response.json();
    } catch (err) {
        throw err;
    }
};

export const getSecurities = async (id = 0, limit = 1000) => {
    try {
        const requestURL = `${HOST}/securities`
        const response = await fetch(requestURL, {
            method: "POST",
            mode: "cors",
            body: JSON.stringify({
                lastId: id,
                limit: limit
            }),
        });
        return await response.json();
    } catch (err) {
        throw err;
    }
};

export const searchSecurities = async (query, size = 10) => {
    try {
        const requestURL = `${HOST}/securities/search?size=${size}`;
        const response = await fetch(requestURL, {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                query: query,
            }),
        });

        const body = await response.json();

        const securities = body.securities.map(sec => {
            return {
                securityId: sec.securityId,
                symbol: sec.symbol,
                name: sec.name,
                region: sec.region,
                logoUrl: sec.logoUrl,
                industry: sec.industry,
                websiteUrl: sec.websiteUrl,
                description: sec.description,
                oldestPriceTime: DateTime.fromISO(sec.oldestPriceTime).toString(),
                oldestPrice: sec.oldestPrice,
                currencyName: sec.currencyName,
                currencySymbol: sec.currencySymbol,
                exchangeName: sec.exchangeName,
                exchangeMic: sec.exchangeMic,
                exchangeLongName: sec.exchangeLongName,
                exchangeRegion: sec.exchangeRegion,
                issueType: sec.issueType,
                sector: sec.sector,
                primarySicCode: sec.primarySicCode,
                tags: sec.tags,
            }
        });

        return {totalSecurities: body.totalSecurities, securities}
    } catch (err) {
        throw err;
    }
};

export const getIndexLedgeActionTypes = async () => {
    try {
        const requestURL = `${HOST}/index/actions`;
        const response = await fetch(requestURL);
        return await response.json();
    } catch (err) {
        throw err;
    }
};

export const getIndexStatusTypes = async () => {
    try {
        const requestURL = `${HOST}/index/statuses`;
        const response = await fetch(requestURL);
        return await response.json();
    } catch (err) {
        throw err;
    }
};


export const validateIndex = async ({name, description, website, brand, code}) => {
    try {
        const requestURL = `${HOST}/validate/index`;
        const response = await fetch(requestURL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                description: description,
                website: website,
                brand: brand,
                code: code
            })
        });
        return await response.json();
    } catch (err) {
        throw err;
    }
}