import "../App.css";
import IndexSecuritiesEdit from "./IndexSecuritiesEdit";
import {Link, useParams} from "react-router-dom";


const IndexEdit = () => {
    const {code} = useParams();
    const changelogPath = `/securities/${code}/changelog`
    return (
        <section className="AddSecurity">
            <h2>INDEXNAME</h2>
            <span>
                Edit Index - <Link to={`/index/${code}/changelog`}>Changelog</Link>
            </span>
            <form>
                <div className="AddSecurityFormContainer">
                    <input type="text" placeholder="Index Name"/>
                    <input type="text" placeholder="URL"/>
                    <input type="text" placeholder="Brand"/>
                    <input type="textarea" placeholder="Summary"/>
                    <input type="text" placeholder="Link for DJ's Connecting Magic"/>
                </div>
                <div className="AddSecurityFormContainer">
                    <button>Save Changes</button>
                </div>
            </form>
            <h2>Add/Remove Securities</h2>
            <IndexSecuritiesEdit/>
        </section>
    );
}

export default IndexEdit;
